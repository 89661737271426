import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Containers
import { Banner } from "../containers/common"
import { TributesList } from "../containers/tributes"

const TributesPage = ({ data }) => {
  const { ogtitle, ogdescription, title, details } = data.tributePage
  const { edges: tributes } = data.tributes

  return (
    <Layout>
      <SEO title={ogtitle} description={ogdescription} />
      <Banner title={title} description={details} />
      <TributesList data={tributes} />
    </Layout>
  )
}

export const query = graphql`
  query TributesPageQuery {
    tributePage: contentfulTributesPage {
      ogtitle
      ogdescription
      title
      details
    }

    tributes: allContentfulTributes(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          createdAt
          location
          alive
          longitude
          latitude
          sliderContent {
            title
            fluid {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          mom {
            firstName
            lastName
            birthDate(fromNow: true)
            image {
              title
              fluid(quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
export default TributesPage
