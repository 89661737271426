import React, { useState } from "react"

// Components
import { SearchBar, ExtendedFilter } from "../../../components/searchBar"
import { TributesList } from "../../../containers/home/tributeSection"
import Pagination from "../../../components/pagination"
import AdSection from "../../../components/adSection"

// Styles
import styles from "./index.module.scss"

const TributesListSection = ({ data }) => {
  const [tributes, setTribute] = useState(data)
  const [filteredTributes, setFilteredTributes] = useState(data)
  const [filterMode, setFilterMode] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [activeLetter, setLetter] = useState(null)
  const [geocodes, setGeocodes] = useState([0, 0])
  const pagesNum = Math.ceil(tributes.length / 9)

  const paginatedData = tributes.slice(
    currentPage === 1 ? 0 : currentPage + 7,
    currentPage * 9
  )

  return (
    <div className={styles.tributesListSection}>
      <div className={styles.searchBarContainer}>
        <div className="max-w-6xl mx-auto px-4 xl:px-0">
          <SearchBar
            data={filteredTributes}
            setTribute={setTribute}
            filterMode={filterMode}
            setFilterMode={setFilterMode}
            setLetter={setLetter}
            geocodes={geocodes}
            setGeocodes={setGeocodes}
            extended
          />
        </div>
      </div>
      <div className="max-w-6xl mx-auto px-4 xl:px-0 mt-12 md:mt-16">
        <ExtendedFilter
          data={filteredTributes}
          setTribute={setTribute}
          sortData={tributes}
          activeLetter={activeLetter}
          setLetter={setLetter}
          geocodes={geocodes}
        />
        <div className="md:pb-16">
          <TributesList data={paginatedData} limitTributes={9} />
        </div>
        {tributes.length > 9 && (
          <Pagination
            route="tributes"
            data={{ pagesNum, currentPage }}
            setCurrentPage={setCurrentPage}
          />
        )}
        {/* <AdSection /> */}
      </div>
    </div>
  )
}

export default TributesListSection
