import React from "react"
import { Link } from "gatsby"

// Stylesheet
import styles from "./index.module.scss"

const Pagination = ({
  data: { currentPage, pagesNum },
  setCurrentPage,
  route,
}) => {
  return (
    <div className={styles.pagination}>
      <div className="flex flex-wrap">
        <Link
          to={`/${route}#banner`}
          onClick={() => setCurrentPage(currentPage < 0 ? currentPage - 1 : 1)}
          className={`${styles.link} ${
            currentPage === 1 ? styles.disabled : ""
          }`}
        >
          Prev
        </Link>
        {Array.from({ length: pagesNum }).map((_, index) => {
          if (index === 7) {
            return (
              <Link
                key={index}
                to={`/${route}#banner`}
                onClick={() => setCurrentPage(index + 1)}
              >
                <button className={`${styles.link} ${styles.more}`}>...</button>
                <Link
                  to={`/${route}#banner`}
                  onClick={() => setCurrentPage(index + 1)}
                  className={`${styles.link} ${
                    currentPage === index + 1 ? styles.active : ""
                  }`}
                >
                  {pagesNum}
                </Link>
              </Link>
            )
          } else if (index < 7) {
            return (
              <Link
                key={index}
                to={`/${route}#banner`}
                onClick={() => setCurrentPage(index + 1)}
                className={`${styles.link} ${
                  currentPage === index + 1 ? styles.active : ""
                }`}
              >
                {index + 1}
              </Link>
            )
          }
        })}

        <Link
          to={`/${route}#banner`}
          onClick={() =>
            setCurrentPage(currentPage < pagesNum ? currentPage + 1 : pagesNum)
          }
          className={`${styles.link} ${
            currentPage === pagesNum ? styles.disabled : ""
          }`}
        >
          Next
        </Link>
      </div>
    </div>
  )
}

export default Pagination
